.faqNavbar {
  padding: 10px 0px;
  border: none;
  border-radius: 0;
  margin-bottom: 20px;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */


.guidemenu {
	color: var(--greencolor);
	opacity:0;
	cursor: pointer
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  height: 100vh
}

.sidebar {
  width: 250px;
  height: 100vh;
  background: var(--creamcolor);
  color: #fff;
  transition: all 0.3s;
  overflow: scroll
}

.sidebar.active {
  margin-left: -250px;
}

.sidemenuList {
  display: block;
  padding: 10px;
}
.sidemenuList h3 {
  margin: 0;
  font-size: 0.875em;
  text-transform: uppercase;
  color: var(--greencolor);
  font-weight: 600;
}
.sidemenuList ul {
  display: block;
  padding: 0;
  margin: 0;
  padding-left: 10px
}

.sidemenuList ul a{
  text-decoration: none;
}

.sidemenuList li {
  display: block;
  padding: 0;
  margin: 10px 0 0;
  align-items: center;
  justify-content: flex-start;
  color: #99a0b0;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.875em;
  transition: color 0.15s ease;
  cursor: pointer
}

.sidemenuList li:hover {
  color: var(--darkcolor);
  
}
.sidemenuListActive {
  color: var(--darkcolor) !important;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
  background: var(--greencolor);
}

ul.CTAs a:hover {
  background: var(--darkcolor);
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100vw;
  padding: 20px;
  height: 100vh;
  transition: all 0.3s;
  overflow: scroll;
  color: black
}

.guideContainer {
	width: 90%;
	
}

.guideTitle {
	color: var(--darkcolor);
	font-weight: bold;
	font-size: 3em;
	margin-top: 20px;
	margin-bottom: 20px;
   	margin-left: 10px;
	border-bottom: 2px solid rgba(55, 93, 112, 0.8);
	padding-bottom: 5px;
}

.guideSmallerTitle {
	color: var(--darkcolor);
	font-weight: bold;
	font-size: 1.8em;
	margin-top: 20px;
	margin-bottom: 20px;
   	margin-left: 10px;
}

.guideLink{
	color: var(--darkcolor);
	
}

.guideLink:hover {
	color: var(--greencolor);
	
}

.galleryImg {
	max-width: 70vw;
	max-height: 250px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.gallerySmallerImg {
	max-width: 60vw;
	max-height: 180px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.galleryLargerImg {
	max-height: 400px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.guideContainer p {
	margin-left: 10px
}


.guideList {
  list-style: none;
  line-height: 1.8;
  margin-left: 20px;
  /* list-style-position: inside; */
}


.guideList li:not(:last-child) {
  margin-bottom: 5px;
}

.guideList li:last-child {
  margin-bottom: 20px;
}

.guideListItem::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--greencolor); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */ 
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.guideListIcon {
	color: var(--greencolor);
	width: 40px
}

.iconlist{
	padding-left: 5%;
	color:var(--greencolor)
}

.textlist{
	color:var(--greencolor)
}

.bolditalicguide {
	font-weight: bold;
	font-style: italic
}


/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  .sidebar {
    margin-left: -250px;
  }
  .sidebar.active {
    margin-left: 0;
  }
  .guidemenu {
		opacity:1
	}
  .galleryImg {
	  max-width: 85vw
	}
  .galleryLargerImg {
		max-width: 85vw
	}
   

}











