:root {
	/*#ff4040, #1E90FF*/
  --modcolor: #5984af;
}

.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width:100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
}

.display-block {
  display: table;
}

.display-none {
  display: none;
}


.contact-form{
    background: #fff;
    margin-top: 10%;
    margin-bottom: 5%;
    width: 70%;
	border-radius: 15px
}

.warning-form {
	background: #fff;
    margin-top: 10%;
    margin-bottom: 5%;
    width: 50%;
	height: 70%;
	border-radius: 15px;
	overflow-y: scroll;
	padding-left: 5%;
	padding-right: 5%;
	padding-top: 2%;
}


.content{
    overflow-y: scroll;
	max-height: 75vh;
}

.contact-form .form-control{
    border-radius:1rem;
}

.contact-image{
	height: 25px;
}

.contact-image div{
	left: 38%;
	position: relative;
  	top: -55px;
    border-radius: 50%;
	width: 100px;
  	height: 100px; 
	background-color: white;
	padding: 10px;
	color: var(--modcolor);
}

@media (min-width: 800px) {
  .contact-image div {
    left: 42%;
  }
}

.buttonclose{
	left: 95%;
	width: 100%;
	position: relative;
  	top: -25px;
	color: var(--modcolor);
	height: 5px;
	cursor: pointer;
}

.buttonclose:hover{
	color: #2A2A8A;
}

@media (min-width: 1000px) {
  .buttonclose {
    left: 97%;
  }
}

@media (min-width: 1300px) {
  .buttonclose {
    left: 98%;
  }
}

.contact-image img{
    border-radius: 6rem;
    width: 11%;
    margin-top: -3%;
    transform: rotate(29deg);
}
.contact-form form{
    padding: 5%;
	padding-bottom: 10%;
}
.contact-form h3{
	padding-top: 0%;
    margin-bottom: 8%;
    text-align: center;
    color: var(--modcolor);
}

.contact-form h5{
    margin-top: -5%;
    text-align: center;
    color: var(--modcolor);
}

.btnContact {
	text-align: center;
	background-color: var(--modcolor);
	border: 0.125rem solid var(--modcolor);
}

.btnContact:hover {
	border: 0.125rem solid var(--modcolor);
	background-color: transparent;
	color: var(--modcolor);
	text-decoration: none;
}



.modal2 {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width:100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}

.imageForm{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 80%;
	height: 80%;
	border-radius: 15px
}

.imgContainer {
	position:absolute;
	margin:auto;
	top:0;
    left:0;
    right:0;
    bottom:0;
  	max-height: 90vh;
	max-width: 90vw
}

.closeButton {
  position: absolute;
  top:0;
  right: 0;
  cursor: pointer
}

.closeButton:hover {
	transform: scale(1.1)
}
