@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css?family=Shrikhand:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Kaushan+Script:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Rock+Salt:regular,bold,italic&subset=latin,latin-ext');


:root {
	/*#ff556e, #f76c6c, #ff4040, #1E90FF, #2f2fa2, #240090, #4056a1*/
	/* new color scheme #4397a1, #5984af, #e57f83, #2f5061, #f3eae5, #f0e7da */
  --bgcolor: white;
  --blackcolor:  #000000;
  --maincolor:  #5984af;
  --creamcolor: floralwhite;
  --pinkcolor: #e57f83;
  --greencolor:  #4397a1;
  --darkcolor: #375d70;
  --fontcolor: #777;
	
	/*#FF033E*/
  --colorbutton: #4397a1;
  --shadeofhoverbutton: #2f5061;
  --progressbarcol: #4397a1;
  --progressbaruncomplete: #e57f83;
	/*#fed84e, #ff556e*/
  --othercolor: #fed84e;
	/*background #FEFBF0*/
  
   --cardcolor: #ff556e;
	/*#ff404*/
   --discountcolor: #ff4040;
   --mainpillcolor: #ff4040;
   --choicepilcolor: #4397a1;
    --cardhoverbg: #f0e7da;
   --cardhoverfont: #4397a1;
   --line-border-fill: #3498db;
   --line-border-empty: #e0e0e0;
	
}

.bodymain {
	background-color:var(--bgcolor);
	color: var(--fontcolor);
	font-family: "Lato", sans-serif;
	height:100%;
	width:100%;
	min-height: 95vh;
	display: flex;
    flex-direction: column;
}

.bodywhite {
	background-color:white;
	color: var(--fontcolor);
	font-family: "Lato", sans-serif;
	height:100%;
	width:100%;
	min-height: 100vh;
	display: flex;
    flex-direction: column;
	
}

footer{
    margin-top: auto;
}

.footer0{
	font-size: 14px
}

body{
	background-color:var(--bgcolor);
	color: var(--fontcolor);
	font-family: "Lato", sans-serif;
	font-size: 16px;
	line-height: 1.8
}

a {
	color: inherit;
	
}


h1 {
	font: 700 2.625rem/3.375rem "Lato", sans-serif;
}

.containerSpacing {
	margin-top: 150px;
	margin-bottom: 150px;
}


.clickLink:hover {
	color:var(--greencolor);
	font-weight: bold
}

.headermodified{
	text-align: center;
	font-family: "Lato", sans-serif;
	font-weight: 800;
	color: var(--darkcolor);
	line-height: 1.2;
	font-size:calc(7.4vw);	
}

.smallerheader {
	text-align: center;
	font-family: "Lato", sans-serif;
	font-size:calc(20px + 1vw);
	font-weight: 500;
	line-height: 1;
}

.smallestheader {
	text-align: center;
	font-size:20px;
	font-weight: 500;
	line-height: 1;
}

.blue {
	line-height: 1.2;
	color: var(--greencolor);
	cursor: pointer;
	text-decoration: underline;
}

.titledesc{
	padding-left:20px;
	padding-right:20px;
}

h5 {
	font: 700 1.125rem/1.625rem "Lato", sans-serif;
}

.btn-solid-lg {
	margin-bottom: 0;
	margin-left: 2rem;
	display: inline-block;
	width: 200px;
	height: 50px; 
	padding-top:10px;
	border-radius: 2rem;
	font: 700 0.875rem/0 "Lato", sans-serif;
	transition: all 0.2s;
	cursor: pointer;
	border: 0.125rem solid var(--darkcolor);
	background-color: transparent;
	color: var(--darkcolor);
	text-decoration: none;
}

.btn-solid-lg:hover {
	border: 0.125rem solid var(--darkcolor);
	background-color: var(--darkcolor);
	color: #fff;
	text-decoration: none;
}
.btn-removemargin{
	padding-top:0px;
	margin-left: 0rem;
}

.smallerbtn{
	height:35px;
	padding: 5px;
	width: 100px;
	
}

.btn-solid-lgopposite {
	margin-bottom: 0;
	margin-left: 2rem;
	display: inline-block;
	width: 200px;
	height: 50px; 
	padding-top:10px;
	border: 0.125rem solid var(--darkcolor);
	border-radius: 2rem;
	background-color: var(--darkcolor);
	color: #fff;
	font: 700 0.875rem/0 "Lato", sans-serif;
	text-decoration: none;
	transition: all 0.2s;
	cursor: pointer;
	
}

.btn-solid-lgopposite:hover {
	border: 0.125rem solid var(--darkcolor);
	background-color: transparent;
	color: var(--darkcolor);
	text-decoration: none;
}



/**************************/
/*     03. Navigation     */
/**************************/
.navbar-custom {
	padding: 0.5rem 1.5rem 0.7rem 1rem;
	box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
	font: 700 0.875rem/0.875rem "Lato", sans-serif;
	transition: all 0.2s;
}

.logo-image {
	cursor: pointer
}

.logo-image img {
    width: 8rem;
}

.logo-imagebrand {
	/*width: 20vw;*/
	padding:5px;
	height: 55px;
}

.navbar-custom .navbar-nav {
	margin-top: 0.75rem;
	margin-bottom: 0.5rem;
}

.navbar-custom .nav-item .nav-link {
	padding: 0.625rem 0.75rem;
	color:  var(--greencolor);
	text-decoration: none;
	transition: all 0.2s ease;
	cursor: pointer;
	font-size: 15px;
}

.navbar-custom .nav-item .nav-link:hover,
.navbar-custom .nav-item .nav-link.active {
	color: var(--pinkcolor);
}

.navbar-toggler-icon {
	color: var(--greencolor);
	
}

/* Dropdown Menu */
.navbar-custom .dropdown:hover > .dropdown-menu {
	display: block; /* this makes the dropdown menu stay open while hovering it */
	min-width: auto;
	animation: fadeDropdown 0.2s; /* required for the fade animation */
}

@keyframes fadeDropdown {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.navbar-custom .dropdown-toggle {
	cursor: default;
}

.navbar-custom .dropdown-toggle:focus { /* removes dropdown outline on focus */
	outline: 0;
}

.navbar-custom .dropdown-menu {
	/*removeright*/
	width: 120px;
	right:0;
	margin-top: 0;
	border: none;
	border-radius: 0.25rem;
	background-color: white;
}

.navbar-custom .dropdown-item {
	/*removepadding*/
	padding: 5px;
	color: var(--greencolor);
	text-decoration: none;
}

.navbar-custom .dropdown-item:hover {
	background-color: white;
}

.navbar-custom .dropdown-item .item-text {
	/*removefontsize*/
	font-size:14px;
	font-weight: 700
	
	
}

.navbar-custom .dropdown-item:hover .item-text {
	color: #ff556e;
	
}

.navbar-custom .dropdown-divider {
	width: 100%;
	height: 1px;
	margin: 0.75rem auto 0.725rem auto;
	border: none;
	background-color: #c4d8dc;
	opacity: 0.2;
}
/* end of dropdown menu */

.navbar-custom .navbar-toggler {
	padding-top: 10px;
	border: none;
	color: #333;
	font-size: 2rem;
}

.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-times{
	display: none;
}

.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-bars{
	display: inline-block;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-bars{
	display: none;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-times{
	display: inline-block;
	margin-right: 0.125rem;
}



/*********************/
/*    04. Header     */
/*********************/
.header {
	position: relative;
	overflow: hidden;
	padding-top: 0rem;
	padding-bottom: 3rem;
	background-color: var(--bgcolor);
	text-align: center;
}

.imgvid {
	cursor: pointer; 
}


.card1old {
  background-color: #FFF;
  
  /*min-width: 300px;
	max-width: 300px;
  width: 300px;
	 height: 450px;
	*/
  margin: 50px auto;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 30px;
  text-align: center;
  cursor: pointer
}

.cardmargin {
}
.card3 {
  
  /*min-width: 300px;
	max-width: 300px;
  width: 300px;
	 height: 450px;
	*/
  
  border-radius: 50px;
  background-color: #91bab7;
  margin: 50px auto;
  box-sizing: border-box;
  padding: 30px;
  text-align: center;
}

.card1 {
  
  /*min-width: 300px;
	max-width: 300px;
  width: 300px;
	 height: 450px;
	*/
  border-radius: 50px;
  background-color: #c98f9b;
  margin: 50px auto;
  box-sizing: border-box;
  padding: 30px;
  text-align: center;
}

/*
.card1:hover {
  background-color: #FAF1D0;
}
.product {
  color: var(--cardcolor);
  position: relative;
  top: 0;
  left: 0;
  transition: 0.5s ease;
}
*/

.thumbnail {
  margin: 0 0 28px;
  color: var(--darkcolor);
  padding: 10px;
  padding-top: 30px
}

.smartcontractdesc{
	color: var(--maincolor);
	font-weight: bold
}

.dappdesc{
	color: var(--greencolor);
	font-weight: bold
}

.productstitle {
  font-size: 3.7vw;
  font-weight: 700;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 5px;
  color:var(--darkcolor);
  transition: 0.5s ease;
  min-height: 140px;
  padding-top: 10%;
}


/*
.title {
  line-height: 1.5;
  margin: 0 0 5px;
  color: var(--maincolor);
  font-size:calc(3.25vw);
  transition: 0.5s ease;
  min-height: calc(15vw);
}
*/

.title1 {
  line-height: 1.5;
  margin: 0 0 5px;
  color: var(--maincolor);
  font-size:calc(30px + 0.2vw);
  transition: 0.5s ease;
}


.titleleft {
  text-align: left;
  padding: 10px;
}

.description {
  font-size:calc(19px + 0.2vw);
  margin: 0 0 48px;
}

.titlecol {
  padding-top: 30px;
  color: var(--darkcolor);
  font-weight: 600;
  font-size:calc(40px + 0.8vw);
  padding-left: 20px;
}

.black {
  padding-top: 50px;
  padding-bottom: 20px;
	
  color: var(--darkcolor);
  text-align: center
}




.card2 {
  background-color: #FFF;
  color: #0F606B;
  width: 200px;
  height: 160px;
  margin: 10px auto;
  border-radius: 12px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.13);
  box-sizing: border-box;
  padding: 5px;
  text-align: center;
}

.card2:hover {
  background-color: #FAF1D0;
}

.titlehuge {
  line-height: 1;
  margin: 0 0 0px;
  text-align: left;
  font-size: 4rem;
}

.titlelarger {
	font-size: 1.3rem;
	color: var(--cardcolor);
	line-height: 1.5rem;
	margin: 0px;
}

.descriptioncard {
  font-size: 0.8rem;
}

.box {
  box-sizing: border-box;
  background-clip:padding-box;
}

.cursorpoint {
	cursor: pointer;
	
}

.iconfill:hover{
	color:var(--cardhoverfont);
	
}

.discount {
	padding: 0.5rem;
	width: 100%;
	background-color: var(--discountcolor);
	color: whitesmoke;
	font-weight: bold;
	font-size: 1.3rem;
}

.text-container {
		margin-bottom: 5rem;
}

/*****************************/
/*     Smart Contract Cards     */
/*****************************/	


.flexThis {
	display: flex;
	flex-wrap: wrap;
	justify-content:center;
}

.smartcontractcard {
	padding: 8px;
	height: 105px;
	width: 200px;
	margin: 10px 15px;
	border: 2px solid rgba(67, 151, 161, 0.7);
	border-radius: 5px;
	font-weight: bold;
	cursor: pointer
}

.smartcontractcard:hover {
	color: black;
	border: 2px solid rgba(67, 151, 161, 1);
}

.smartcontractcard div:first-child {
	color: rgba(67, 151, 161, 0.7);
}

.smartcontractcard:hover div:first-child {
	color: rgba(67, 151, 161, 1);
}


/*****************************/
/*     Statistics    */
/*****************************/	

.statsContainer {
	margin-top: 50px;
	background-color: var(--darkcolor);
	padding: 30px;
	min-height: 200px;
	color: white;
	font-size: 25px;
	text-align: center
}

.statsDiv {
	margin: 0px 40px
}

.statsTitle {
	font-size: 40px;
	font-weight: bold;
}

/*****************************/
/*    Banner    */
/*****************************/	
.bannerContainer {
	
	background-color: var(--darkcolor);
	padding: 10px;
	color: white;
	font-size: 15px;
	text-align: center
}



/*****************************/
/*     Smart Contract Preview    */
/*****************************/	

.smartcontractprocessdiv {
	padding: 8px;
	width: 100%;
	border: 2px solid rgba(67, 151, 161, 0.7);
	border-radius: 5px;
	margin: 15px 0px;
}

.smartcontractprocessimg {
	width: 100%;
}

.smartcontractprocesscolor {
	 color: var(--greencolor)
}

.smartcontractprocesstitle {
	font-size: 20px;
	color: var(--darkcolor);
	font-weight: bold;
	text-decoration: underline
}


/*****************************/
/*     18. Media Queries     */
/*****************************/	
/* Min-width 768px */

@media (max-width: 768px) {
	.description {
	  font-size:19px;
	}
	.title {
	  font-size:30px;
	  min-height: calc(10vw);
	}
	.logo-imagebrand {
		/*
	width: 25vw;*/
		width:80%;
		height:100%
	}
	.headermodified{
		font-size:50px;
		
	}
	
	.productstitle {
	  font-size:50px;
	  padding-top: 5%;
	   
	}
	
	.btn-solid-lgopposite {
		margin-left: 0rem;
	}
	.btn-solid-lg {
		margin-left: 0rem;
	}
	
}


@media (max-width: 400px) {
	/*
	.header .btn-solid-lg {
		padding-top:5px;
		height: 40px;
		width:150px;
		font-size: 0.7rem;
		
	}
	.header .btn-solid-lgopposite {
		padding-top:5px;
		height: 40px;
		width:150px;
		font-size: 0.7rem;
	}
	*/
	.card1 {
		width: 90%;
		margin: 20px auto;
		
	}
	.card3 {
		width: 90%;
		margin: 20px auto;
		
	}
	
}

/* Min-width 992px */

@media (min-width: 950px) {
	
	.blue {
		line-height: 4.4rem;
	}
	
	

}

@media (min-width: 992px) {
	.productstitle {
  	min-height: 170px;
	}
	

	h1 {
		font-size: 3rem;
		line-height: 3.875rem;
	}

	/* Header */
	.header {
		padding-top: 2rem;
	}

	.header h1 {
		font-size: 4rem;
		line-height: 4.875rem;
		letter-spacing: -1px;
	}
	
	.title {
	  min-height: calc(10vw);
	}
	
}

@media (min-width: 1400px) {
	.productstitle {
  min-height: 220px;
}
	
}





.container1 .card{
  position: relative;
  width: 320px;
  height: 450px;
  background: #232323;
  border-radius: 20px;
  overflow: hidden;
}

.container1 .card .contentBx{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  transition: 1s;
  z-index: 10;
}

.container1 .card:hover .contentBx{
  height: 210px;
}

.container1 .card .contentBx h2{
  position: relative;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  margin: 0;
}

.container1 .card .contentBx .size, .container1 .card .contentBx .color {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  transition: 0.5s;opacity: 0;
  visibility: hidden;
  padding-top: 0;
  padding-bottom: 0;
}

.container1 .card:hover .contentBx .size{
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.container1 .card:hover .contentBx .color{
  opacity: 1;
  visibility: visible;
  transition-delay: 0.6s;
}

.container1 .card .contentBx .size h3, .container1 .card .contentBx .color h3{
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
}

.container1 .card .contentBx .size span{
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 14px;
  display: inline-block;
  color: #111;
  background: #fff;
  margin: 0 5px;
  transition: 0.5s;
  color: #111;
  border-radius: 4px;
  cursor: pointer;
}

.container1 .card .contentBx .size span:hover{
  background: #9bdc28;
}

.container1 .card .contentBx .color span{
  width: 20px;
  height: 20px;
  background: #ff0;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.container1 .card .contentBx .color span:nth-child(2){
  background: #9bdc28;
}

.container1 .card .contentBx .color span:nth-child(3){
  background: #03a9f4;
}

.container1 .card .contentBx .color span:nth-child(4){
  background: #e91e63;
}

.container1 .card .contentBx a{
  display: inline-block;
  padding: 10px 20px;
  background: #fff;
  border-radius: 4px;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
  color: #111;
  opacity: 0;
  transform: translateY(50px);
  transition: 0.5s;
  margin-top: 0;
}

.container1 .card:hover .contentBx a{
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.75s;
  
}

/* rolling images background-color: var(--darkcolor);*/

.marquee-wrapper {
	padding: 20px;
	width: 100vw;
	overflow: hidden
}
.marquee-wrapper .marquee-block{
  --total-marquee-items:6;
  height: 180px;
  width: calc(320px * (var(--total-marquee-items)));
  /* overflow hidden if outside row and container
  overflow: hidden;
	*/
  box-sizing: border-box;
  position: relative;
}
.marquee-inner{
  display: block;
  width: 200%;
  position: absolute;
}
.marquee-inner p{
  font-weight: 800;
  font-size: 30px;
  font-family: cursive;
}
.marquee-inner.to-left{
  animation: marqueeLeft 25s linear infinite;
}
.marquee-inner.to-right{
  animation: marqueeRight 25s linear infinite;
}
.marquee-item{
  width: 300px;
  height: auto;
  display: inline-block;
  margin: 0 10px;
  float: left;
  transition: all .2s ease-out;
}

.floatingleft {
	height : 180px;
	width: 300px;
}

@keyframes marqueeLeft{
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes marqueeRight{
  0% { 
    left: -100%; 
  }
  100% {
   left: 0; 
  }
}

/* FAQ */

.detailThree {
	position: relative;
	transition: background-color 0.15s;
	border-top: 2px solid var(--darkcolor);
	color: var(--darkcolor);
}

.detailThree:hover {
}

.detailThree[open] {
	color: var(--greencolor);
	border-top: 2px solid var(--greencolor);
}

.detailThree summary::-webkit-details-marker {
	display: none;
}

.summaryThree {
	width: 100%;
	padding: 0.2rem 0;
	position: relative;
	cursor: pointer;
	font-size: 25px;
	font-weight: 700;
	list-style: none;
	
}

.summaryThree:after {
	content: "+";
	color: inherit;
	position: absolute;
	font-size: 1.75rem;
	line-height: 0;
	margin-top: 0.75rem;
	right: 0;
	font-weight: 500;
	transform-origin: center;
	transition: 200ms linear;
}

details[open] .summaryThree:after {
	transform: rotate(45deg);
	font-size: 2rem;
}

.summaryThree {
	outline: 0;
}

.detailThree div {
	font-size: 1rem;
	margin: 0;
	padding-top: 0.2rem;
	color: var(--darkcolor);
}

@keyframes det {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
details + details {
	margin-top: .5rem;
}

/* Poll */
.pollDiv {
	padding: 10px 0
}

.checkbox {
  --background: #fff;
  --border: #d1d6ee;
  --border-hover: #bbc1e1;
  --border-active: #1e2235;
  --tick: #fff;
  --stroke: var(--tick);
  position: relative;
  display:flex;
  
}

.checkbox span {
	line-height: 1.2;
	padding-left: 5px;
	cursor: pointer
	
}
.checkbox input,
.checkbox svg {
  width: 21px;
  height: 21px;
  display: block;
}
.checkbox input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  transition: box-shadow 0.3s;
  box-shadow: inset 0 0 0 var(--s, 1px) var(--greencolor, var(--border));
}

.checkbox input:hover {
  --s: 2px;
  --b: var(--greencolor);
}
.checkbox input:checked {
  --b: var(--darkcolor);
  --s: 11px;
}
.checkbox svg {
   --scale: 0;
  pointer-events: none;
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--stroke, var(--border-active));
  position: absolute;
  top: 0;
  left: 0;
  width: 21px;
  height: 21px;
  transform: scale(var(--scale, 1)) translateZ(0);
}

.checkbox.bounce input:checked + svg {
  -webkit-animation: bounce 0.4s linear forwards 0.2s;
          animation: bounce 0.4s linear forwards 0.2s;
}

.pollSuggestion {
	box-shadow: inset 0 0 0 var(--s, 1px) var(--greencolor, var(--border));
	border: 0;
}

.pollSuggestion:focus {
	box-shadow: inset 0 0 0 var(--s, 2px) var(--greencolor, var(--border));
}

@-webkit-keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

/* Testimonial */
.originalquote blockquote {
	font-weight: 500;
	font-size: 16px;
	max-width: 600px;
	line-height: 1.6;
	position: relative;
	margin: 0;
	padding: .5rem;
}

.originalquote blockquote:before,
.originalquote blockquote:after {
	position: absolute;
	color: var(--greencolor);
	font-size: 5rem;
	width: 3rem;
	height: 3rem;
}

.originalquote blockquote:before {
	content: '“';
	left: -4rem;
	top: -3rem;
}

.originalquote blockquote:after {
	content: '”';
	right: -3rem;
	bottom: 1rem;
}

.originalquote cite {
	padding-left: 5px;
	font-weight: bold;
	line-height: 2;
	text-align: left;
	color: var(--greencolor);
}

.quote {
  display: inline-block;
  margin: 1em;
  overflow: hidden;
}
.quote blockquote {
  border: solid 2px var(--greencolor);
  display: inline-block;
  margin: 0;
  padding: 1em;
  position: relative;
}
.quote blockquote:before {
  background-color: #fff;
  bottom: -10%;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: -10%;
  transform: rotate(-15deg) skew(5deg);
}
.quote blockquote cite {
  display: block;
  font-style: italic;
  text-align: right;
  font-weight: bold;
  color: var(--greencolor);
}

.quote blockquote > * {
  position: relative;
  z-index: 1;
}



