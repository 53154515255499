
.box{
    margin:0 5%;
    padding: 10px 0 0px 0px
}


.box ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    transition: all 0.5s linear;
    top:0
}

.box ul:last-of-type{top:80px}

.box ul:before{
    content: "";
    display: block;
    width: 0;
    height: 100%;
    border:1px solid #fff;
    position: absolute;
    top:0;
    left:30px
}

.box ul li{
    margin: 20px 60px 60px;
    position: relative;

    background-color:white;
    color:white;
    border-radius: 10px;
    line-height: 20px;
    width: 90%
}

.productstepimage{
    width: 95%
}


.box ul li > span{
    content: "";
    display: block;
    width: 0;
    height: 100%;
    border:1px solid #fff;
    position: absolute;
    top:0;
    left:-30px
}

.box ul li > span:before{
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
	/*#ff6d6d*/
    background-color:var(--pinkcolor);
    border:2px solid #fff;
    position: absolute;
    left:-10px
}

.box ul li > span:before{top:-10px}
.box ul li > span:after{top:95%}

.box .info{
	padding: 15px;
	background-color:var(--maincolor);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	font-size: 18px;
	
}


.box .info:first-letter{
	text-transform: capitalize;
	line-height: 1.7}

.box .name{
    margin-top: 10px;
    text-transform: capitalize;
    font-style: italic;
    text-align: right;
    margin-right: 20px
}


.box .time span{
    position: absolute;
    left: -90px;
    color:var(--maincolor);
    font-size:15px;
    font-weight: bold;
}
.box .time span:first-child{top:-16px}




/* pricing */

.pricingtabs {
  left: 0%;
  position: relative;
  width: 100%;
  border-radius: 5px;
  min-width: 240px;
}

.pricingtabs .content section h2,
.pricingtabs ul li label {
  font-weight: bold;
  font-size: 18px;
}

.pricingtabs ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}
.pricingtabs ul li {
  box-sizing: border-box;
  flex: 1;
  width: 50%;
  padding: 0 10px;
  text-align: center;
}
.pricingtabs ul li label {
  transition: all 0.3s ease-in-out;
  color: grey;
  padding: 5px auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pricingtabs ul li label br {
  display: none;
}

.pricingtabs ul li label:hover, .pricingtabs ul li label:focus, .pricingtabs ul li label:active {
  outline: 0;
  color: var(--greencolor);
}

.pricingtabs .slider {
  position: relative;
  width: 50%;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}
.pricingtabs .slider .indicator {
  position: relative;
  width: 50px;
  max-width: 100%;
  margin: 0 auto;
  height: 4px;
  background: var(--greencolor);
  border-radius: 1px;
}


.pricingtabchosen label {
  cursor: default !important;
  color: var(--greencolor) !important;
}

.pricingtabs .sliderquarter {
  position: relative;
  width: 25%;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}
.pricingtabs .sliderquarter .indicator {
  position: relative;
  width: 50px;
  max-width: 100%;
  margin: 0 auto;
  height: 4px;
  background: var(--greencolor);
  border-radius: 1px;
}


.slidertab0 {
	transform: translateX(0%);	
}

.slidertab2{
	transform: translateX(200%);	
}

.slidertab3{
	transform: translateX(300%);	
}

.slidertab1 {
	transform: translateX(100%);
	
}

@-webkit-keyframes content {
  from {
    opacity: 0;
    transform: translateY(5%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes content {
  from {
    opacity: 0;
    transform: translateY(5%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}


.panel {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 10px;
  position: relative;
  width: 100%;
  z-index: 10;
}

.pricing-table {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
}

.pricing-table * {
  text-align: center;
  text-transform: uppercase;
}

.pricing-plan {
  border-bottom: 1px solid #e1f1ff;
  padding: 25px;
}

.pricing-plan:last-child {
  border-bottom: none;
}

.pricing-img {
  color: var(--greencolor);
  margin-bottom: 60px;
}

.pricing-mainheader {
	font-size: 90px;
	font-family: "Pacifico", cursive
}

.pricing-header {
  color: var(--greencolor);
  font-weight: 600;
  letter-spacing: 1px;
}

.pricing-features {
  
  font-weight: 600;
  letter-spacing: 1px;
  margin: 40px 0 20px 0;
  list-style: none
}

.pricing-features-item {
  border-top: 1px solid #e1f1ff;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0;
}

.pricing-price {
  color: #016FF9;
  display: block;
  font-size: 32px;
  font-weight: 700;
}

.pricingtext {
  padding-left: 10%;
  font-size: 20px;
  font-weight: 500;
	
}

.pricingtitlesmall{
  line-height: 1.8em;
  padding-top: 30px;
  font-family: 'Comfortaa', cursive;
  color: var(--darkcolor);
  font-weight: 800;
  font-size:calc(30px + 0.2vw);
  padding-left: 20px;
}

.pricingtitle{
  font-size:calc(50px + 0.2vw);
}

.pricingsub {
	padding-top: 20px;
}

/* Gallery */
.gallerycolpadding {
	padding: 5px
	
}
.gallerybrowsermenuBar {
  border: 1px solid #f1f1f1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.gallerybrowsermenuRow {
  padding: 0px;
  background: #f1f1f1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.gallerybrowserleft {
  padding-left: 5px;
}

.gallerybrowsermenuRow:after {
  content: "";
  display: table;
  clear: both;
}
.gallerybrowsermenuBarDots {
  margin: 2px;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  display: inline-block;
}
.menuBarDots1 {
  background-color: #ED594A;
}
.menuBarDots2 {
  background-color: #FDD800;
}
.menuBarDots3 {
  background-color: #5AC05A;
}

.gallerywrapper {
  width: 90%;
  margin: 40px auto;
}

.gallerycontent {
	height: 21vw;
	overflow:scroll;
}

.galleryimage {
	padding: 0;
	width: 100%;	
	min-height: 100%
}

.gallery {
  display: flex;
  padding: 2px;
  transition: 0.3s;
}
.gallery__column {
  margin: 25px;
  display: flex;
  flex-direction: column;
  width: 50%;
}
.gallery__link {
  margin: 2px;
  overflow: hidden;
}

.gallery__thumb:hover .gallery__caption {
  opacity: 1;
}
.gallery__thumb {
  position: relative;
}
.gallery__image {
  display: block;
  width: 100%;
  transition: 0.3s;
}
.gallery__image:hover {
  transform: scale(1.1);
}
.gallery__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 25px 15px 15px;
  width: 100%;
  font-size: 16px;
  color: white;
  opacity: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  transition: 0.3s;
}

/* Vider */

/* User Gallery */

.usercard {
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  
}

.usercardbody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  min-height: 10px;
}

.usercardbody h4 {
  font-size: 20px;
}

.usercardimageholder {
	height:200px;
	overflow: hidden;
}

.usergallerypic {
	width:100%;
}



@media (max-width: 786px) {
	.box .time{
    display:none
	}
	.box ul:before{
    display:none
	}
	.box ul li > span{
    display: none;
	}
	.box{
    margin:0 0%;
    padding: 0px 0 0px 0px
	}
	.box ul li{
    margin: 20px 20px 20px;
	}	
	.gallerycontent {
		height: 65vw;
	}
}

@media (max-width: 992px) {
  .pricingtabs ul li label {
    white-space: initial;
  }
  .pricingtabs ul li label br {
    display: initial;
  }
  .pricingtabs ul li label Icon {
    height: 1.5em;
  }
  .gallerycontent {
	height: 30vw;
	}
}
@media (max-width: 600px) {
  .pricingtabs .content {
    margin-top: 20px;
  }
}

@media (min-width: 800px) {
  .pricing-table {
    flex-direction: row;
  }
}

@media (min-width: 1000px) {
  .pricing-plan {
    border-bottom: none;
    border-right: 1px solid #e1f1ff;
    flex-basis: 100%;
    padding: 25px 50px;
  }

  .pricing-plan:last-child {
    border-right: none;
  }
}


